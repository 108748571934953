import axios from 'axios';
import { config } from '../config';


const fetchStations = (coordinates, fuelType) => {
  let { northEastLat, northEastLng, southWestLat, southWestLng } = coordinates;

  let headers = {
    headers: {
      'API-Key': `${config.newRelicApiKey}`,
      'Content-Type': 'application/json',
    }
  }

  let query = {
    "query":
    // eslint-disable-next-line no-useless-concat
      "{ actor { account(id: 2000041) { nrql(query: \"select * from FuelPriceSampleTest WHERE ((" + `${northEastLng}`+ ">= longitude) and (longitude >= " + `${southWestLng}` +  ")) and ((" + `${northEastLat}` +  " >= latitude) and (latitude >= " + `${southWestLat}` +  "))  and price" + `${fuelType}` + " > 0 limit max since 1 month ago\") { results } } } }",
    "variables": ""
  }

  return (
    axios.post(`${config.corsURL}https://api.newrelic.com/graphql`, query, headers)
  )
}

export default fetchStations;
