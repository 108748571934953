import axios from 'axios';
import { config } from '../config';


const getSuggestions = async(address, token) => {

  let apiKey = `${config.bingApiKey}`;
  let query = `https://dev.virtualearth.net/REST/v1/Autosuggest?query=${address}&maxResults=3&userRegion=MX&countryFilter=MX&culture=es-MX&key=${apiKey}`;

  return (
    await axios.get(query, { cancelToken: token })
  )
}

export default getSuggestions;