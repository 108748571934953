import React from 'react';
import {
  DialogTrigger, Dialog, Heading,
  ActionButton, Divider, Content, Text, Flex
} from '@adobe/react-spectrum';
import styles from './PermisionModal.module.css';
import stationIcon from '../../components/images/station-icon-group.png';


const PermisionModal = props => {
  const { openAskPermision } = props;

  let refDialogPlacement = React.useRef();

  let dialogPlacementElement = (
    <span ref={refDialogPlacement}></span>
  );


  return (
    <React.Fragment>
      <DialogTrigger
        hideArrow
        isOpen={openAskPermision}
        targetRef={refDialogPlacement}
      >
        <ActionButton
          isDisabled
          UNSAFE_className={styles.actionButtonDialog}
        >
          Ubicación inicial
        </ActionButton>
        <Dialog>
          <Heading>
            <Flex justifyContent={'space-between'}>
              <p>
                Ubica la <span className={styles.titleHighlight}>mejor estación</span>
                <br />
                para cargar
                <span className={styles.titleHighlightSecondary}> combustible</span> cerca de ti
              </p>
              <img src={stationIcon} height={80} alt={'station-icon'}/>
            </Flex>
          </Heading>
          <Divider />
          <Content>
            <Text>Para acceder es necesario conceder permisos de ubicación</Text>
          </Content>
        </Dialog>
      </DialogTrigger>
      {dialogPlacementElement}
    </React.Fragment>
  );
};

export default PermisionModal;