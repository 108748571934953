import React, { useEffect, useState } from 'react';
import { DialogTrigger, View, Flex, ActionButton,
  Heading, Divider, Content, Text, Button, Link } from '@adobe/react-spectrum';
import Share from '@spectrum-icons/workflow/Share';
import Close from '@spectrum-icons/workflow/Close';
import { Icon } from '@react-spectrum/icon';
import { ReactComponent as RouteIconSvg } from '../images/icon-route.svg';
import GetAddressFromCoordinates from '../../utils/GetAddress';
import calcDistance from '../../utils/CalcDistance';
import styles from './StationDetails.module.css';


const StationDetails = props => {
  const { stationDetailsIsOpen, openedStationData, mapRef, userCurrentLocation, onCloseStationDetails,
    lowerPriceStationData, maximizeStationDetails } = props;

  const [maximizeContent, setMaximizeContent] = useState(false);

  useEffect(() => {
    if (!maximizeStationDetails) {
      setMaximizeContent(false);
    }
  },[maximizeStationDetails]);

  let windowWidthMobile = window.innerWidth < 700;

  let refDialogPlacement = React.useRef();

  let dialogPlacementElement = (
    <span ref={refDialogPlacement}></span>
  );

  let stationNameElement = (
    <Heading
      margin={'size-0'}
      maxWidth={'70%'}
      alignSelf={'center'}
      height={'fill-content'}
      UNSAFE_className={styles.heading}
    >
      {openedStationData.name}
    </Heading>
  );

  let stationDistanceElement;
  if (userCurrentLocation) {
    stationDistanceElement = (
      <Text UNSAFE_className={styles.stationDistanceText}>
        {calcDistance(userCurrentLocation, { lat: openedStationData.latitude, lng: openedStationData.longitude })}
      </Text>
    );
  }

  let closeDialogButton = (
    <Button
      isQuiet={true}
      variant={'secondary'}
      margin={'size-0'}
      marginStart={'auto'}
      onPress={() => onCloseStationDetails()}
    >
      <Close
        size={'S'}
        aria-label={'close'}
      />
    </Button>
  );

  let shareAndCloseButtonDesktop = (
    <View
      marginTop={'size-0'}
      marginStart={'auto'}
      height={'fill-content'}
      UNSAFE_className={styles.shareButtonDesktop}
    >
      <Flex>
        <Link
          isQuiet
          variant={'secondary'}
          alignSelf={'center'}
        >
          <a
            href={`https://api.whatsapp.com/send?text=Estación+de+Gasolina+${openedStationData.creId}+https://www.google.com/maps/place/${openedStationData.latitude},${openedStationData.longitude}`}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <Share
              size={'S'}
              aria-label={'share'}
            />
          </a>
        </Link>
        {closeDialogButton}
      </Flex>
    </View>
  );

  let shareButtonMobile = (
    <View
      minWidth={'size-100'}
      marginY={'size-25'}
      marginX={'size-100'}
      height={'fill-content'}
      UNSAFE_className={styles.shareButtonMobile}
    >
      <Link
        isQuiet
        variant={'secondary'}
      >
        <a
          href={`https://api.whatsapp.com/send?text=Estación+de+Gasolina+${openedStationData.creId}+https://www.google.com/maps/place/${openedStationData.latitude},${openedStationData.longitude}`}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <Share
            size={'S'}
            aria-label={'share'}
          />
        </a>
      </Link>
    </View>
  );

  let routeButton = (
    <View
      marginStart={'auto'}
      marginEnd={!windowWidthMobile && 'size-300'}
      minWidth={'size-100'}
    >
      <Button
        variant={'primary'}
        elementType={'a'}
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={`https://www.google.com/maps?saddr=${userCurrentLocation.lat},${userCurrentLocation.lng}&daddr=${openedStationData.latitude},${openedStationData.longitude}&travelmode=driving`}
      >
        <Icon>
          <RouteIconSvg />
        </Icon>
        <Text>Ir</Text>
      </Button>
    </View>
  );

  let allFuelInStation = {
    'Magna': openedStationData.priceRegular,
    'Premium': openedStationData.pricePremium,
    'Diesel': openedStationData.priceDiesel
  };
  let fuelValuesLength = Object.values(allFuelInStation).filter(f => f !== 0).length;
  let fuelDetailElement = (
    Object.entries(allFuelInStation).map(([k, v], i) => {
      let priceValueFormatted = '';
      let divider = '';
      let content = '';
      if (v !== 0) {
        let priceValue = v.toString();
        priceValueFormatted = priceValue.slice(0, 2) + '.' + priceValue.slice(2, 4);
        if (fuelValuesLength - 2 >= i) {
          divider = (
            <Flex direction={'column'}>
              <Divider
                size={'M'}
                orientation={'vertical'}
                height={'size-600'}
                marginStart={'size-100'}
              />
            </Flex>
          );
        }
        content = (
          <React.Fragment>
            <Flex direction={'column'}>
              <Text>{k}</Text>
              <Text UNSAFE_style={{ fontSize: '16px', fontWeight: 'bold' }}>${priceValueFormatted}</Text>
            </Flex>
            {divider}
          </React.Fragment>
        );
      }

      return (
        <React.Fragment key={i}>
          <Flex direction={'row'}>
            {content}
          </Flex>
        </React.Fragment>
      )
    })
  );

  let addressElement = (
    <Text
      width={windowWidthMobile ? '100%' : 'size-4600'}
      UNSAFE_className={styles.addressText}
    >
      <GetAddressFromCoordinates
        lat={openedStationData.latitude}
        lng={openedStationData.longitude}
        mapRef={mapRef}
      />
    </Text>
  );

  let lowerPriceBadge;
  if ((openedStationData.latitude === lowerPriceStationData.latitude) && (openedStationData.longitude === lowerPriceStationData.longitude)) {
    lowerPriceBadge = (
      <Flex
        direction={'column'}
        marginStart={'auto'}
        marginY={'auto'}
      >
        <View UNSAFE_className={styles.lowerpriceView}>
          <Text
            UNSAFE_className={styles.lowerpriceBadge}
            marginX={'size-100'}
          >
            MENOR PRECIO
          </Text>
        </View>
      </Flex>
    );
  }

  let contentHeight = '0px';
  if (maximizeContent) {
    contentHeight = 'auto';
  }


  return (
    <React.Fragment>
      <View
        position={'absolute'}
        top={'90vh'}
      >
        <DialogTrigger
          type={'popover'}
          mobileType={'tray'}
          hideArrow
          isOpen={stationDetailsIsOpen}
          //onOpenChange={() => windowWidthMobile && onCloseStationDetails()} // Cerrar modal al hacer click overlay
          targetRef={refDialogPlacement}
        >
          <ActionButton
            isDisabled
            UNSAFE_className={styles.actionButtonDialog}
          >
            Detalles
          </ActionButton>
          <View>
            <span onClick={() => setMaximizeContent(!maximizeContent)}>
              <Flex
                direction={'row'}
                minHeight={'size-800'}
              >
                <View
                  width={'size-5000'}
                  backgroundColor={'gray-200'}
                  paddingStart={'size-200'}
                  paddingEnd={'size-200'}
                >
                  <View
                    alignSelf={'center'}
                    marginY={'size-200'}
                    width={'100%'}
                  >
                    <Flex>
                      {stationNameElement}
                      {shareAndCloseButtonDesktop}
                    </Flex>
                    <Flex alignItems={'center'}>
                      {stationDistanceElement}
                      {shareButtonMobile}
                      {routeButton}
                    </Flex>
                  </View>
                </View>
              </Flex>
              <Flex
                direction={'row'}
                margin={'size-200'}
              >
                <Content
                  width={'100%'}
                  height={windowWidthMobile && contentHeight}
                >
                  {addressElement}
                  <Flex
                    direction={'row'}
                    gap={'size-100'}
                    marginTop={'size-100'}
                  >
                    {fuelDetailElement}
                    {lowerPriceBadge}
                  </Flex>
                </Content>
              </Flex>
            </span>
          </View>
        </DialogTrigger>
        {dialogPlacementElement}
      </View>
    </React.Fragment>
  );
};

export default StationDetails;