import React from 'react';
import { View, Flex, Button, Divider } from '@adobe/react-spectrum';
import { Icon } from '@react-spectrum/icon';
import { ReactComponent as IconPath } from '../images/icon-path.svg';
import { ReactComponent as IconFuel } from '../images/icon-fuel.svg';
import styles from './Navigation.module.css';


const Navigation = props => {
  const { onChangeFuelType, fuelType, onOpenPath } = props;

  let fuelTypeColor = '#2DBB0B';
  if (fuelType === 'Magna') {
    fuelTypeColor = (
      '#2DBB0B'
    );
  }
  if (fuelType === 'Premium') {
    fuelTypeColor = (
      '#EA170A'
    );
  }
  if (fuelType === 'Diesel') {
    fuelTypeColor = (
      '#B07113'
    );
  }

  let menuFuelType = (
    <Button
      variant={'overBackground'}
      width={'100%'}
      height={'size-1000'}
      isQuiet={true}
      UNSAFE_style={{ borderRadius: '0px', fontSize: '17px' }}
      onPress={() => onChangeFuelType(fuelType)}
    >
      <span style={{ color: fuelTypeColor }}>
        <Icon size={'L'}>
          <IconFuel />
        </Icon>
      </span>
      {/*<Text
        marginStart={'size-50'}
        UNSAFE_style={{textAlign: 'left'}}
      >
        Tipo
      </Text>*/}
    </Button>
  );

  let menuCreatePath = (
    <Button
      variant={'overBackground'}
      width={'100%'}
      height={'size-1000'}
      isQuiet={true}
      UNSAFE_style={{ borderRadius: '0px', fontSize: '17px' }}
      onPress={onOpenPath}
    >
      <Icon size={'L'}>
        <IconPath />
      </Icon>
      {/*<Text UNSAFE_style={{textAlign: 'left'}}>Recorrido</Text>*/}
    </Button>
  );


  return (
    <React.Fragment>
      <View
        backgroundColor={'static-gray-900'}
        width={'size-1000'}
        paddingTop={'size-1000'}
        paddingBottom={'size-1000'}
        paddingRight={'size-250'}
        paddingLeft={'size-250'}
        UNSAFE_className={styles.navigationBar}
      >
        <Flex
          width={'100%'}
          justifyContent={'center'}
        >
          {menuFuelType}
        </Flex>
        <Divider
          size={'S'}
          UNSAFE_className={styles.dividerHorizontalNavigation}
        />
        <Flex
          width={'100%'}
          justifyContent={'center'}
        >
          {menuCreatePath}
        </Flex>
      </View>
    </React.Fragment>
  );
};

export default Navigation;