import React from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import markerRegular from '../images/marker-regular.png';
import markerPremium from '../images/marker-premium.png';
import markerDiesel from '../images/marker-diesel.png';
import lowerPriceIcon from '../images/lower-price.png';
import markerUserLocation from '../images/icon-user-location.png';
import markerPathEnd from '../images/marker-path-end.png';
import markerPathStart from '../images/marker-path-start.png';
import styles from './MarkersElement.module.css';


const MarkersElement = props => {
  const { stations, userCurrentLocation, fuelType, onClickMarker, onOpenStationDetails, path, lowerPriceStationData,
    openedStationData } = props;

  let stationIcon;
  if (fuelType === 'Regular') {
    stationIcon = (
      markerRegular
    );
  }
  if (fuelType === 'Premium') {
    stationIcon = (
      markerPremium
    );
  }
  if (fuelType === 'Diesel') {
    stationIcon = (
      markerDiesel
    );
  }

  let stationMarkers = (
    stations.map((s, i) => {
      let stationStyles;
      let priceStyles;
      let lowerPriceElement = '';
      let isLowerPriceStation = (s.latitude === lowerPriceStationData.latitude) && (s.longitude === lowerPriceStationData.longitude);
      let isOpenedStation = openedStationData && (s.latitude === openedStationData.latitude) &&
        (s.longitude === openedStationData.longitude);
      if (isLowerPriceStation) {
        if (isOpenedStation) {
          stationStyles = (
            `${styles.customMarkerLowerImgOpened}`
          );
          priceStyles = (
            `${styles.customMarkerLowerPriceOpened}`
          );
        } else {
          stationStyles = (
            `${styles.customMarkerLowerImg}`
          );
          priceStyles = (
            `${styles.customMarkerLowerPrice}`
          );
        }
        lowerPriceElement = (
          `<img src=${lowerPriceIcon} class=${styles.lowerPriceImg} alt='lower-price' />`
        );
      } else {
        if (isOpenedStation) {
          stationStyles = (
            `${styles.customMarkerImgOpened}`
          );
          priceStyles = (
            `${styles.customMarkerPriceOpened}`
          );
        } else {
          stationStyles = (
            `${styles.customMarkerImg}`
          );
          priceStyles = (
            `${styles.customMarkerPrice}`
          );
        }
      }

      let stationIconElement = (
        `<img src=${stationIcon} class=${stationStyles} alt='station' />`
      );

      let priceValue = s[`price${fuelType}`].toString();
      let priceValueFormatted = priceValue.slice(0, 2) + '.' + priceValue.slice(2, 4);

      return (
        <Marker
          key={i}
          position={[s.latitude, s.longitude]}
          icon={
            L.divIcon({
              // eslint-disable-next-line no-useless-concat
              html: `${stationIconElement}${lowerPriceElement}<span class=${priceStyles}>$` + `${priceValueFormatted}` + `</span>`,
              className: `${styles.customMarker}`
            })
          }
          onClick={e => {
            onClickMarker(e.latlng);
            onOpenStationDetails(s);
          }}
        >
        </Marker>
      )
    })
  );

  let userIconElement = (
    `<img src=${markerUserLocation} class=${styles.customUserMarkerImg} alt='user' />`
  );
  let userLocationMarker = (
    <Marker
      position={[userCurrentLocation.lat, userCurrentLocation.lng]}
      icon={
        L.divIcon({
          html: `${userIconElement}`,
          className: `${styles.customUserMarker}`
        })
      }
    >
    </Marker>
  );

  let pathStartIconElement = (
    `<img src=${markerPathStart} class=${styles.customPathMarkerImg} alt='path-start' />`
  );
  let pathEndIconElement = (
    `<img src=${markerPathEnd} class=${styles.customPathMarkerImg} alt='path-end' />`
  );
  let pathStartMarker;
  let pathEndMarker;
  if (path) {
    pathStartMarker = (
      <Marker
        position={path.pathStart}
        icon={
          L.divIcon({
            html: `${pathStartIconElement}`,
            className: `${styles.customUserMarker}`
          })
        }
      >
      </Marker>
    );
    pathEndMarker = (
      <Marker
        position={path.pathEnd}
        icon={
          L.divIcon({
            html: `${pathEndIconElement}`,
            className: `${styles.customUserMarker}`
          })
        }
      >
      </Marker>
    );
  }


  return (
    <React.Fragment>
      {stationMarkers}
      {userLocationMarker}
      {pathStartMarker}
      {pathEndMarker}
    </React.Fragment>
  );
};

export default MarkersElement;