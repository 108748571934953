import React, { Component } from 'react';
import L, { latLngBounds } from 'leaflet';
import { Polyline } from 'react-leaflet';
import { Flex } from '@adobe/react-spectrum';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from '../../components/Navigation/Navigation';
import MapElement from '../Map/MapElement';
import StationDetails from '../../components/DetailsCard/StationDetails';
import PathCard from '../../components/PathCard/PathCard';
import PermisionModal from '../../components/Landing/PermisionModal';
import getPolylineCoordinates from '../../utils/GetPolylineCoordinates';
import fetchStations from '../../utils/FetchStations';
import styles from './Main.module.css';
import mapPlaceholderImg from '../../components/images/map-placeholder.png';


class Main extends Component {
  state = {
    fuelType: 'Regular',
    pathStart: null,
    pathEnd: null,
    searchPathStations: false,
    allStationsData: null,
    lowerPriceStationData: {},
    openedStationData: null,
    stationDetailsIsOpen: false,
    maximizeStationDetails: false,
    pathIsOpen: false,
    userCurrentLocation: { lat: 24.1863838, lng: -99.5470077 }, // Default a toda la república mexicana
    radius: 1000,
    polylinePathData: [],
    openAskPermision: true,
  }

  componentDidMount() {
    this.getNavigatorLocation();
    this.setIntervalGetNavigatorLocation();
  }

  componentWillUnmount() {
    clearInterval(this.setIntervalNavigatorLocation);
  };

  setIntervalGetNavigatorLocation = () => {
    // Actualizar marker de la ubicación del usuario cada cierto tiempo

    this.setIntervalNavigatorLocation = setInterval(() => this.getNavigatorLocation('updateLocation'), 300000);
  }

  getNavigatorLocation = type => {
    const navigatorLocationSuccess = location => {
      if (location && (this.state.userCurrentLocation.lat !== location.coords.latitude) &&
        (this.state.userCurrentLocation.lng !== location.coords.longitude)) {
        this.setState({ userCurrentLocation: { lat: location.coords.latitude, lng: location.coords.longitude },
          openAskPermision: false },
          () => {
            if (type !== 'updateLocation') {
              this.getArea('init');
            }
          }
        );
      }
    }
    const navigatorLocationError = error => {
      let message;
      if (error.code === 1) {
        message = 'Permiso de ubicación denegado';
      } else if (error.code === 2) {
        message = 'Imposible obtener ubicación';
      } else {
        message = 'Ha ocurrido un error';
      }
      this.showToast('error', message);
    }
    navigator.geolocation.getCurrentPosition(navigatorLocationSuccess, navigatorLocationError);
  }

  getArea = type => {
    // Se obtiene el área dónde se buscarán estaciones, esta área es cuadrada o rectángular.
    // 'init': área basado en las coordenadas del usuario + radio alrededor + área visible del mapa / 'path': área para recorridos, basado en punto de inicio
    // y fin + un radio alrededor de ellas / 'expand': expande el área previa con el fin de obtener resultados / 'dragging': basado en el área
    // que ve el usuario en el mapa cuando lo arrastra

    let finalAreaArr;
    if (type === 'init') {
      let squareArea = L.latLng(this.state.userCurrentLocation.lat, this.state.userCurrentLocation.lng).toBounds(this.state.radius);
      this.fitbounds([[squareArea._northEast.lat, squareArea._northEast.lng], [squareArea._southWest.lat, squareArea._southWest.lng]]);

      let viewportArea = this.mapRef.leafletElement.getBounds();
      finalAreaArr = [[viewportArea._northEast.lat, viewportArea._northEast.lng], [viewportArea._southWest.lat, viewportArea._southWest.lng]];
    } else if (type === 'path') {
      // Debe hacerse un área cuadrada dónde estén incluidas las coordenadas del 'path' (recorrido) para que se tenga suficiente
      // área para hacer la búsqueda de estaciones, de lo contrario el área puede quedar muy angosta

      this.fitbounds([[this.state.pathStart], [this.state.pathEnd]]);
      setTimeout(this.panBy, 500); // Posterior a fitbounds en mobile, es útil 'panear' el mapa para evitar que se solape con otros elementos

      let boundsStart = L.latLng(this.state.pathStart[0], this.state.pathStart[1]).toBounds(this.state.radius / 2);
      let boundsEnd = L.latLng(this.state.pathEnd[0], this.state.pathEnd[1]).toBounds(this.state.radius / 2);
      let allBoundsArr = [[boundsStart._northEast.lat, boundsStart._northEast.lng], [boundsStart._southWest.lat, boundsStart._southWest.lng],
        [boundsEnd._northEast.lat, boundsEnd._northEast.lng], [boundsEnd._southWest.lat, boundsEnd._southWest.lng]];

      let searchableArea = latLngBounds(allBoundsArr.map(c => c));
      finalAreaArr = [[searchableArea._northEast.lat, searchableArea._northEast.lng], [searchableArea._southWest.lat, searchableArea._southWest.lng]];
    } else if (type === 'expand') {
      let mapCurrentZoom = this.mapRef.leafletElement.getBoundsZoom(this.mapRef.leafletElement.getBounds());
      let zoomExpanding = mapCurrentZoom - 2;
      let viewportArea = this.mapRef.leafletElement.setZoom(zoomExpanding, { animate: false }).getBounds();

      finalAreaArr = [[viewportArea._northEast.lat, viewportArea._northEast.lng], [viewportArea._southWest.lat, viewportArea._southWest.lng]];
    } else if (type === 'dragging') {
      let viewportArea = this.mapRef.leafletElement.getBounds();
      finalAreaArr = [[viewportArea._northEast.lat, viewportArea._northEast.lng], [viewportArea._southWest.lat, viewportArea._southWest.lng]];
    }

    this.fetchCloserStations(finalAreaArr, type);
  }

  fitbounds = (bounds, padding) => {
    // Encajar en el mapa todas las coordenadas dadas

    let finalPadding = padding;
    let windowWidthMobile = window.innerWidth < 700;
    if (windowWidthMobile) {
      finalPadding = { paddingTopLeft: [50, 0], paddingBottomRight: [100, 0] };
    }

    this.mapRef.leafletElement.fitBounds(bounds, finalPadding);
  }

  fetchCloserStations = (finalAreaArr, type) => {
    let coordinates = {
      'northEastLat': finalAreaArr[0][0],
      'northEastLng': finalAreaArr[0][1],
      'southWestLat': finalAreaArr[1][0],
      'southWestLng': finalAreaArr[1][1]
    }

    fetchStations(coordinates, this.state.fuelType)
      .then(res => {
        let results = res.data.data.actor.account.nrql.results;
        if (results.length) {
          this.setState({ allStationsData: results },
            () => this.getBestStation(results, type)
          );
        } else {
          this.setState({ stationDetailsIsOpen: false });
          if (type === 'expand') {
            // Si ya habíamos expandido previamente, eso quiere decir que no hay datos en esa área

            this.showToast('info');
          } else {
            this.getArea('expand');
          }
        }
      })
      .catch(error => console.debug(error));
  }

  getBestStation = (stations, type) => {
    // Obtener la estación de menor precio en los resultados, y si es necesario se centra mapa en la ubicación del usuario y esta estación

    let lowerPriceStation = stations.reduce((a, b) => a[`price${this.state.fuelType}`] < b[`price${this.state.fuelType}`] ? a : b);
    this.setState({ lowerPriceStationData: lowerPriceStation });

    if ((type === 'init' || type === 'expand') && !this.state.pathIsOpen) {
      let locationAndLowerPriceStation = [[this.state.userCurrentLocation.lat, this.state.userCurrentLocation.lng],
        [lowerPriceStation.latitude, lowerPriceStation.longitude]];
      this.fitbounds(locationAndLowerPriceStation, { paddingTopLeft: [200, 0], paddingBottomRight: [200, 0] });
    }

    this.onOpenStationDetails(lowerPriceStation);
  }

  setPolylinePath = (type, station) => {
    // Dibujar o remover línea que marca la ruta entre los dos puntos del recorrido ('path')

    if (type === 'draw') {
      getPolylineCoordinates(this.state.pathStart, this.state.pathEnd, station, (coordinates) => {
        this.setState({ polylinePathData: coordinates });
      });
    } else if (type === 'remove') {
      this.setState({ polylinePathData: [] });
    }
  }

  onChangeFuelType = fuelType => {
    let nextFuelType;
    if (fuelType === 'Regular') {
      nextFuelType = (
        'Premium'
      );
    } else if (fuelType === 'Premium') {
      nextFuelType = (
        'Diesel'
      );
    } else if (fuelType === 'Diesel') {
      nextFuelType = (
        'Regular'
      );
    }
    this.setState({ fuelType: nextFuelType, pathIsOpen: false, searchPathStations: false },
      () => {
        this.setState({ pathStart: null, pathEnd: null, polylinePathData: [] });
        this.getArea('init');
        this.onCloseStationDetails();
      }
    );
  }

  onOpenStationDetails = stationData => {
    // La estación del state 'openedStationData' es para mostrar en la ficha de detalles de la estación
    // y si aplica, también es la seleccionada para el recorrido (path)

    this.setState({ openedStationData: stationData, maximizeStationDetails: true },
      () => {
        if (!this.state.pathIsOpen) {
          this.setState({ stationDetailsIsOpen: true, maximizeStationDetails: false });
        } else if (stationData && this.state.pathIsOpen && this.state.pathStart && this.state.pathEnd) {
          this.setPolylinePath('draw', stationData);
        }
      }
    );
  }

  onCloseStationDetails = () => {
    this.setState({ stationDetailsIsOpen: false });
  }

  onOpenPath = () => {
    this.onCloseStationDetails();
    this.setState({ pathIsOpen: true, openedStationData: null });
  }

  onClosePath = () => {
    this.setPolylinePath('remove');
    this.setState({ pathIsOpen: false },
      () => this.setState({ openedStationData: null, searchPathStations: false })
    );
    this.getArea('init');
  }

  onSetPathStartCoordiantes = coordinates => {
    this.setState({ pathStart: coordinates });
  }

  onSetPathEndCoordiantes = coordinates => {
    this.setState({ pathEnd: coordinates });
  }

  onSearchPathStations = () => {
    this.setState({ searchPathStations: true, allStationsData: null, lowerPriceStationData: {}, openedStationData: null },
      () => this.getArea('path')
    );
  }

  panBy = () => {
      // Desplazar el mapa ligeramente para evitar que alguno de los elementos solape los marcadores del mapa

    let windowWidthMobile = window.innerWidth < 700;
    if (windowWidthMobile) {
      let offset = this.mapRef.leafletElement.getSize().y * 0.2; 
      this.mapRef.leafletElement.panBy(new L.Point(0, offset), { animate: true });
    }
  }

  showToast = (type, message) => {
    let finalMessage = message;
    if (!message) {
      finalMessage = 'No hay estaciones cercanas, intenta en otra área';
    }

    toast[type](finalMessage, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  createMapRef = ref => {
    this.mapRef = ref;
  }


  render() {
    const { fuelType, stationDetailsIsOpen, openedStationData, pathIsOpen, userCurrentLocation,
      searchPathStations, pathStart, pathEnd, lowerPriceStationData, polylinePathData, openAskPermision,
      maximizeStationDetails } = this.state;

    let stationDetailsElement;
    if (openedStationData) {
      stationDetailsElement = (
        <StationDetails
          stationDetailsIsOpen={stationDetailsIsOpen}
          maximizeStationDetails={maximizeStationDetails}
          openedStationData={openedStationData}
          lowerPriceStationData={Object.keys(lowerPriceStationData).length && lowerPriceStationData}
          userCurrentLocation={userCurrentLocation}
          onCloseStationDetails={this.onCloseStationDetails}
          mapRef={this.mapRef}
        />
      );
    }

    let polylinePathElement;
    if (polylinePathData.length) {
      polylinePathElement = (
        <Polyline
          positions={polylinePathData}
          color={'#2c2c2c'}
        />
      );
    }

    let permisionModal = (
      <PermisionModal
        openAskPermision={openAskPermision}
      />
    );

    let mapPlaceholder;
    if (openAskPermision) {
      mapPlaceholder = (
        <img
          src={mapPlaceholderImg}
          className={styles.mapPlaceholder}
          width={'100%'}
          alt={'map-placeholder'}
        />
      );
    }


    return (
      <Flex UNSAFE_className={styles.flexMain}>
        <Navigation
          fuelType={fuelType}
          onChangeFuelType={this.onChangeFuelType}
          onOpenPath={this.onOpenPath}
        />
        {mapPlaceholder}
        <MapElement
          stations={this.state.allStationsData}
          fuelType={fuelType}
          onOpenStationDetails={this.onOpenStationDetails}
          openedStationData={stationDetailsIsOpen && openedStationData}
          createMapRef={this.createMapRef}
          userCurrentLocation={userCurrentLocation}
          pathIsOpen={pathIsOpen}
          path={searchPathStations && { pathStart: pathStart, pathEnd: pathEnd }}
          lowerPriceStationData={Object.keys(lowerPriceStationData).length && lowerPriceStationData}
          getArea={this.getArea}
          panBy={this.panBy}
        >
          {polylinePathElement}
        </MapElement>
        {stationDetailsElement}
        <PathCard
          pathIsOpen={pathIsOpen}
          onClosePath={this.onClosePath}
          onSetPathStartCoordiantes={this.onSetPathStartCoordiantes}
          onSetPathEndCoordiantes={this.onSetPathEndCoordiantes}
          onSearchPathStations={this.onSearchPathStations}
          path={searchPathStations && { pathStart: pathStart, pathEnd: pathEnd }}
          openedStationData={searchPathStations && openedStationData}
          onOpenStationDetails={this.onOpenStationDetails}
          setPolylinePath={this.setPolylinePath}
        />
        <ToastContainer />
        {permisionModal}
      </Flex>
    )
  }
}

export default Main;
