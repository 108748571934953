import React from 'react';
import Main from './containers/Main/Main';
import { Provider } from '@adobe/react-spectrum';

function App() {
  return (
    <Provider>
      <Main />
    </Provider>
  );
}

export default App;
