import L from 'leaflet';
import 'leaflet-control-geocoder';
import { config } from '../config';


const getCoordinatesFromAddress = (address, callback) => {
  const geocoderApi = new L.Control.Geocoder.Bing(`${config.bingApiKey}`);

  const getCoordinates = callback => {
    geocoderApi.geocode(
      address,
      results => {
        return (
          callback([results[0].center.lat, results[0].center.lng])
        );
   })
  }

  getCoordinates(callback);
};

export default getCoordinatesFromAddress;