import axios from 'axios';
import { config } from '../config';


const getPolylineCoordinates = (start, end, station, callback) => {

  let apiKey = `${config.bingApiKey}`;
  let query=`https://dev.virtualearth.net/REST/v1/Routes/Driving?wayPoint.1=${start[0]},${start[1]}&viaWaypoint.2=${station.latitude},${station.longitude}&waypoint.3=${end[0]},${end[1]}&routeAttributes=routePath&key=${apiKey}`;

  axios.get(query)
    .then(res => {
      let coordinates = res.data.resourceSets[0].resources[0].routePath.line.coordinates.map(c => c);

      callback(coordinates);
    })
    .catch(error => console.log(error));
};

export default getPolylineCoordinates;