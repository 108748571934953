import L from 'leaflet';


const calcDistance = (userCurrentLocation, stationLocation) => {
  let distanceInMts =  L.latLng(userCurrentLocation.lat, userCurrentLocation.lng).distanceTo(stationLocation);

  let roundedDistanceInMts = Math.round(distanceInMts * 10) / 10;

  let units;
  let formattedDistance;
  let isMts = (roundedDistanceInMts.toString().split('.'))[0].length <= 3;
  if (isMts) {
    formattedDistance = roundedDistanceInMts;
    units = 'm';
  } else {
    formattedDistance = Math.round((roundedDistanceInMts / 1000) * 10) / 10;
    units = 'km';
  }

  return (
    formattedDistance + ' ' + units
  )
}

export default calcDistance;