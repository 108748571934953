import React, { Component } from 'react';
import axios from 'axios';
import { Item, ActionGroup, View } from '@adobe/react-spectrum';
import getCoordinatesFromAddress from '../../utils/GetCoordinates';
import getSuggestions from '../../utils/GetSuggestions';
import styles from './PathCard.module.css';


class AddressSuggestions extends Component {
  state = {
    suggestions: null,
    showSuggestions: false,
    selectedSuggestion: null,
  }

  source = axios.CancelToken.source();

  componentDidMount() {
    this.getSuggestions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.address !== prevProps.address) {
      if (this.props.address !== this.state.selectedSuggestion) {
        this.getSuggestions();
      }
      if (this.state.showSuggestions) {
        this.props.onSetValidInput('');
      }
    }
  }

  componentWillUnmount() {
    if (this.source) {
      // Cancelamos la petición axios de 'getSuggestions' antes de que se desmonte el componente, para así evitar que intente renderearse
      // cuando se borra el 'address'
      this.source.cancel('Suggestions Canceled');
    }
  }

  getSuggestions = () => {
    getSuggestions(this.props.address, this.source.token)
      .then(res => this.formattedSuggestions(res.data.resourceSets[0].resources[0].value))
      .catch(error => console.log(error));
  }

  formattedSuggestions = suggestionsRes => {
    if (suggestionsRes && suggestionsRes.length) {
      let formattedSuggestions = [];
      suggestionsRes.map((s, i) => formattedSuggestions.push({ id: i, name: s.address.formattedAddress }));
      this.setState({ suggestions: formattedSuggestions },
        () => this.setState({ showSuggestions: true })
      );
    } else {
      this.setState({ showSuggestions: false});
    }
  }

  onSelectSuggestion = selectedSuggestion => {
    let selectedSuggestionFormatted = selectedSuggestion.split('key')[0];
    this.setState({ showSuggestions: false, selectedSuggestion: selectedSuggestionFormatted },
      () => this.props.onSelectSuggestion(selectedSuggestionFormatted)
    );
    this.getCoordinates(selectedSuggestionFormatted);
  }

  getCoordinates = selectedSuggestion => {
    getCoordinatesFromAddress(selectedSuggestion, c => this.validateCoordinates(c));
  }

  validateCoordinates = coordinates => {
    if (coordinates.length === 2) {
      this.props.onSetValidInput('valid');
      this.props.onSetPathCoordiantes(coordinates);
    } else {
      this.props.onSetValidInput('invalid');
    }
  }


  render() {
    const { suggestions, showSuggestions } = this.state;

    let suggestionsItem = (
      <Item key={'empty'}>No hay coincidencias</Item>
    );
    if (showSuggestions) {
      suggestionsItem = (
        suggestions.map((s, i) =>
          <Item key={s.name + 'key' + i}>{s.name}</Item>
        )
      );
    }

    let suggestionsElement;
    if (showSuggestions) {
      suggestionsElement = (
        <ActionGroup
          onAction={this.onSelectSuggestion}
          orientation={'vertical'}
          selectionMode={'single'}
          density={'compact'}
          isQuiet
          width={'size-4600'}
          height={'size-1600'}
          UNSAFE_style={{ overflow: 'hidden' }}
          UNSAFE_className={styles.suggestionsActionGroup}
        >
          {suggestionsItem}
        </ActionGroup>
      );
    }


    return (
      <React.Fragment>
        <View
          width={'100%'}
          backgroundColor={'gray-100'}
        >
          {suggestionsElement}
        </View>
      </React.Fragment>
    )
  }
}

export default AddressSuggestions;