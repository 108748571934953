import React, { useState } from 'react';
import L from 'leaflet';
import 'leaflet-control-geocoder';
import { config } from '../config';


const GetAddressFromCoordinates = props => {
  const [address, setAddress] = useState('');

  const { lat, lng, mapRef } = props;

  const geocoderApi = new L.Control.Geocoder.Bing(`${config.bingApiKey}`);

  let mapRefLeafletElement = mapRef.leafletElement;

  geocoderApi.reverse(
    { lat: lat, lng: lng },
    mapRefLeafletElement.options.crs.scale(mapRefLeafletElement.getZoom()), results => {
      if (results) {
        setAddress(results[0].name);
      }
    }
  );

  return (
    <React.Fragment>
      {address}
    </React.Fragment>
  );
};

export default GetAddressFromCoordinates;