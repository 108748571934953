import React, { useState, useEffect } from 'react';
import { DialogTrigger, View, Flex, ActionButton,
  Heading, Content, Text, TextField, Button } from '@adobe/react-spectrum';
import Search from '@spectrum-icons/workflow/Search';
import Close from '@spectrum-icons/workflow/Close';
import MoreSmallListVert from '@spectrum-icons/workflow/MoreSmallListVert';
import { Icon } from '@react-spectrum/icon';
import { ReactComponent as IconFuel } from '../images/icon-fuel.svg';
import { ReactComponent as RouteIconSvg } from '../images/icon-route.svg';
import { ReactComponent as PinIconSvg } from '../images/icon-pin.svg';
import AddressSuggestions from './AddressSuggestions';
import styles from './PathCard.module.css';


const PathCard = props => {
  const { pathIsOpen, onClosePath, onSetPathStartCoordiantes, onSetPathEndCoordiantes,
    onSearchPathStations, openedStationData, onOpenStationDetails, path, setPolylinePath } = props;

  const [typedStartAddress, setTypedStartAddress] = useState('');
  const [validStartInput, setValidStartInput] = useState('');
  const [typedEndAddress, setTypedEndAddress] = useState('');
  const [validEndInput, setValidEndInput] = useState('');

  useEffect(() => {
    if (!typedStartAddress.length) {
      setValidStartInput('');
    }
    if (!typedEndAddress.length) {
      setValidEndInput('');
    }
    if (!pathIsOpen) {
      setTypedStartAddress('');
      setTypedEndAddress('');
      setValidStartInput('');
      setValidEndInput('');
    }
    if (!validStartInput || !validEndInput) {
      onOpenStationDetails(null);
    }
    if ([!validStartInput.length, !validEndInput.length].includes(true)) {
      setPolylinePath('remove');
    }
  }, [typedStartAddress.length, typedEndAddress.length, pathIsOpen, validStartInput, validEndInput,
    onOpenStationDetails, setPolylinePath]);

  let refDialogPlacement = React.useRef();

  let dialogPlacementElement = (
    <span ref={refDialogPlacement}></span>
  );

  let windowWidthMobile = window.innerWidth < 700;

  let headingTitle = (
    <Heading
      margin={'size-0'}
      maxWidth={'70%'}
      UNSAFE_className={styles.headingTitle}
      height={'fill-content'}
    >
      Recorrido
    </Heading>
  );

  let routeButton = (
    <View
      marginStart={'size-25'}
      minWidth={'size-100'}
    >
      <Button
        variant={'primary'}
        isDisabled={!openedStationData || [!validStartInput.length, !validEndInput.length].includes(true)}
        elementType={'a'}
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={openedStationData && `https://www.google.com/maps/dir/?api=1&origin=${path.pathStart[0]}, ${path.pathStart[1]}&destination=${path.pathEnd[0]}, ${path.pathEnd[1]}&travelmode=driving&waypoints=${openedStationData.latitude},${openedStationData.longitude}`}
      >
        <Icon>
          <RouteIconSvg />
        </Icon>
        <Text UNSAFE_className={styles.routeButtonText}>Ir</Text>
      </Button>
    </View>
  );

  let searchButton = (
    <View
      minWidth={'size-100'}
      UNSAFE_className={styles.viewSearchButton}
    >
      <Button
        variant={'primary'}
        onPress={onSearchPathStations}
        isDisabled={[!validStartInput.length, !validEndInput.length].includes(true)}
      >
        <Search
          size={'S'}
          aria-label={'search'}
        />
        <Text UNSAFE_className={styles.searchButton}>
          <span>Buscar</span> Estaciones
        </Text>
      </Button>
    </View>
  );

  let addressStartElement = (
    <View width={'100%'}>
      <TextField
        icon={<Icon><PinIconSvg /></Icon>}
        placeholder={'Punto de partida'}
        width={'100%'}
        isQuiet
        onChange={e => setTypedStartAddress(e)}
        value={typedStartAddress}
        validationState={validStartInput}
        aria-label={'Punto de partida'}
        UNSAFE_className={styles.addressTextField}
      />
    </View>
  );

  let addressEndElement = (
    <View width={'100%'}>
      <TextField
        icon={<Icon><PinIconSvg /></Icon>}
        placeholder={'Punto de destino'}
        width={'100%'}
        isQuiet
        onChange={e => setTypedEndAddress(e)}
        value={typedEndAddress}
        validationState={validEndInput}
        aria-label={'Punto de destino'}
        UNSAFE_className={styles.addressTextField}
      />
    </View>
  );

  let suggestionsStartElement;
  if (typedStartAddress) {
    suggestionsStartElement = (
      <AddressSuggestions
        address={typedStartAddress}
        onSelectSuggestion={e => setTypedStartAddress(e)}
        onSetValidInput={e => setValidStartInput(e)}
        onSetPathCoordiantes={onSetPathStartCoordiantes}
      />
    );
  }

  let suggestionsEndElement;
  if (typedEndAddress) {
    suggestionsEndElement = (
      <AddressSuggestions
        address={typedEndAddress}
        onSelectSuggestion={e => setTypedEndAddress(e)}
        onSetValidInput={e => setValidEndInput(e)}
        onSetPathCoordiantes={onSetPathEndCoordiantes}
      />
    );
  }

  let stationSelectedElement;
  if (openedStationData && validStartInput.length && validEndInput.length) {
    stationSelectedElement = (
      <View
        width={'100%'}
        paddingTop={'size-50'}
      >
        <Flex alignContent={'center'}>
          <Flex direction={'column'}>
            <span style={{ color: '#9a9a9a', display: 'flex'  }}>
               <MoreSmallListVert
                 size={'S'}
                 width={'15px'}
                 aria-label={'station-start'}
               />
            </span>
            <span style={{ color: '#6e6e6e', display: 'flex' }}>
              <Icon
                size={'S'}
                width={'13px'}
                marginEnd={'size-100'}
              >
                <IconFuel />
              </Icon>
            </span>
            <span style={{ color: '#9a9a9a', display: 'flex' }}>
               <MoreSmallListVert
                 size={'S'}
                 width={'15px'}
                 aria-label={'station-start'}
               />
            </span>
          </Flex>
          <Text
            marginY={'auto'}
            maxWidth={'size-3600'}
            UNSAFE_className={styles.stationName}
          >
            {openedStationData.name}
          </Text>
        </Flex>
      </View>
    );
  }

  let closeDialogButton = (
    <Flex>
      <Button
        isQuiet
        variant={'secondary'}
        margin={'size-0'}
        marginStart={'auto'}
        onPress={() => onClosePath()}
      >
        <Close
          size={'S'}
          aria-label={'close'}
        />
      </Button>
    </Flex>
  );

  let closeDialogButtonMobile = (
    <Flex UNSAFE_className={styles.flexButtonMobile}>
      <Button
        isQuiet
        variant={'secondary'}
        margin={'size-0'}
        marginStart={'auto'}
        onPress={() => onClosePath()}
      >
        <Close
          size={'S'}
          aria-label={'close'}
        />
      </Button>
    </Flex>
  );


  return (
    <React.Fragment>
      <View
        position={'absolute'}
        top={'80vh'}
      >
        <DialogTrigger
          type={'popover'}
          mobileType={'tray'}
          hideArrow={true}
          isOpen={pathIsOpen}
          //onOpenChange={() => onClosePath()} // Cerrar dialog en click overlay
          targetRef={refDialogPlacement}
        >
          <ActionButton
            isDisabled
            UNSAFE_className={styles.actionButtonDialog}
          >
            Recorrido
          </ActionButton>
          <View UNSAFE_className={styles.pathCardWrapper}>
            <View UNSAFE_className={styles.pathCardContainer}>
              <Flex direction={'column'}>
                <Flex
                  direction={'row'}
                  UNSAFE_className={styles.flexRowCloseButton}
                >
                  <View
                    width={'100%'}
                    backgroundColor={'gray-200'}
                  >
                    <View
                      alignSelf={'end'}
                      marginTop={'size-50'}
                      width={'100%'}
                    >
                      {closeDialogButton}
                    </View>
                  </View>
                </Flex>

                <Flex
                  direction={'row'}
                  minHeight={'size-800'}>
                  <View
                    width={'size-5000'}
                    backgroundColor={'gray-200'}
                    paddingStart={'size-200'}
                    paddingEnd={'size-200'}
                  >
                    <View
                      alignSelf={'center'}
                      marginTop={'size-50'}
                      marginBottom={windowWidthMobile ? 'size-85' : 'size-200'}
                      width={'100%'}
                    >
                      <Flex UNSAFE_className={styles.flexContainerHeader}>
                        <Flex justifyContent={'space-between'}>
                          {headingTitle}
                          {closeDialogButtonMobile}
                        </Flex>
                        <Flex>
                          {searchButton}
                          {routeButton}
                        </Flex>
                      </Flex>
                    </View>
                  </View>
                </Flex>
              </Flex>
              <Flex
                direction={'row'}
                marginY={windowWidthMobile ? 'size-100' : 'size-200'}
                marginX={'size-200'}
              >
                <Content width={'100%'}>
                  <Flex direction={'row'}>
                    {addressStartElement}
                  </Flex>
                  <Flex direction={'row'}>
                    {suggestionsStartElement}
                  </Flex>
                  <Flex>
                    {stationSelectedElement}
                  </Flex>
                  <Flex direction={'row'}>
                    {addressEndElement}
                  </Flex>
                  <Flex direction={'row'}>
                    {suggestionsEndElement}
                  </Flex>
                </Content>
              </Flex>
            </View>
          </View>
        </DialogTrigger>
        {dialogPlacementElement}
      </View>
    </React.Fragment>
  );
};

export default PathCard;