import React, { Component } from 'react';
import { View } from '@adobe/react-spectrum';
import Crosshairs from '@spectrum-icons/workflow/Crosshairs';
import { Map, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-control';
import MarkersElement from '../../components/Map/MarkersElement';
import styles from './MapElement.module.css';


class MapElement extends Component {
  state = {
    mapCenter: [19.3968908, -99.1566962],
    zoom: 5,
    unBlockDragging: false,
    isDragging: false,
  }

  componentDidMount = () => {
    if (this.props.userCurrentLocation) {
      this.setMapOnUserLocation();
    }
    this.setTimeOutDragging();
  }

  componentDidUpdate = prevProps => {
    if (prevProps.fuelType !== this.props.fuelType) {
      this.setState({ isDragging: false, unBlockDragging: false },
        () => this.setTimeOutDragging()
      );
    }
    if (prevProps.pathIsOpen !== this.props.pathIsOpen) {
      if (this.props.pathIsOpen) {
        this.setState({ isDragging: false, unBlockDragging: false });
        clearTimeout(this.timeout);
      } else {
        this.setTimeOutDragging();
      }
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  setMapOnUserLocation = () => {
    let resetLocation = [19.3968908, -99.1566962];
    this.setState({ mapCenter: resetLocation },
      () => this.setState({ mapCenter: [this.props.userCurrentLocation.lat, this.props.userCurrentLocation.lng], zoom: 15 })
    );
  }

  updateZoom = zoom => {
    this.setState({ zoom: zoom });
  }

  setTimeOutDragging = () => {
    this.timeout = setTimeout(this.unBlockDragging, 10000);
  }

  unBlockDragging = () => {
    this.setState({ unBlockDragging: true });
  }

  onDragging = position => {
    if (this.state.unBlockDragging && position !== undefined) {
      let positionX = Math.abs(position.x);
      let positionY = Math.abs(position.y);
      let bigDrag = 70;
      let mapIsDragging = positionX >= bigDrag || positionY >= bigDrag;
      if (mapIsDragging) {
        this.setState({ isDragging: true });
      }
    }
  }

  onClickMarker = latlng => {
    this.setState({ mapCenter: [latlng.lat, latlng.lng], zoom: 16 },
      () => setTimeout(this.props.panBy, 500)
    );

  }

  updateMobileZoom = () => {
    let windowWidthMobile = window.innerWidth < 700;
    if (windowWidthMobile) {
      let newZoom = this.state.zoom - 1;
      this.updateZoom(newZoom);
    }
  }


  render() {
    const { mapCenter, zoom, isDragging } = this.state;
    const { stations, fuelType, onOpenStationDetails, createMapRef, userCurrentLocation,
      path, lowerPriceStationData, openedStationData, children, getArea } = this.props;


    let markers;
    if (stations) {
      markers = (
        <MarkersElement
          userCurrentLocation={userCurrentLocation}
          stations={stations}
          fuelType={fuelType}
          onClickMarker={this.onClickMarker}
          onOpenStationDetails={onOpenStationDetails}
          openedStationData={openedStationData}
          path={path}
          lowerPriceStationData={lowerPriceStationData}
        />
      );
    }

    let searchHereButton;
    if (isDragging) {
      searchHereButton = (
        <Control position={'topright'}>
          <button
            className={styles.searchHereButton}
            onClick={() => { getArea('dragging'); this.updateMobileZoom(); }}
          >
            Buscar en esta área
          </button>
        </Control>
      );
    }

    let maxViewHeight = window.innerHeight - 50 + 'px';
    let windowWidthMobile = window.innerWidth < 700;

    return (
      <React.Fragment>
        <View UNSAFE_className={styles.viewMap}>
          <Map
            className={styles.mapContainer}
            style={{ 'maxHeight': windowWidthMobile && `${maxViewHeight}` }}
            center={mapCenter}
            zoom={zoom}
            zoomControl
            minZoom={12}
            onZoomEnd={e => this.updateZoom(e.target._zoom)}
            onMoveEnd={e => this.onDragging(e.target.dragging._lastPos)}
            ref={createMapRef}
          >
            <TileLayer
              attribution={'&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
              url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
            />
            <Control position={'topleft'}>
              <button
                className={styles.locationButton}
                onClick={() => this.setMapOnUserLocation()}
              >
                <Crosshairs
                  size={'S'}
                  aria-label={'location'}
                />
              </button>
            </Control>
            {searchHereButton}
            {markers}
            {children}
          </Map>
        </View>
      </React.Fragment>
    )
  }
}

export default MapElement;